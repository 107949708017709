import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {Resolver} from "../shared/services/resolver.service";
import {ItemComponent} from "./item/item.component";

const routes: Routes = [
  {
    path: 'item/:slug',
    component: ItemComponent,
    resolve: {
      data: Resolver
    }
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class ProductRoutingModule { }
