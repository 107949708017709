import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorService } from "./error.service";
import { SettingService } from "./setting.service";
import { User } from '../data/user';
@Injectable({
  providedIn: 'root'
})
export class MemberService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private error:ErrorService,
    private setting:SettingService
  ) { }

  //登录的方法
  getLogin(user: User): Observable<any> {
    return this.http.put(this.setting.api.api_url+'/in/login', user, this.httpOptions).pipe(
      tap(_ => this.error.log(`userid=${user.id}`)),
      catchError(this.error.handleError<any>('updateHero'))
    );
  }
  //获取基础数据
  getBase(token:any):Observable<any>{
    return this.http.put(this.setting.api.api_url+'/customer/base', token, this.httpOptions).pipe(
      tap(_ => this.error.log(``)),
      catchError(this.error.handleError<any>(''))
    );
  }
}
