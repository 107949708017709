import { Component, OnInit } from '@angular/core';
import { MemberService} from "../../service/member.service";
import {User} from "../../data/user";
import { AuthService} from "../../service/auth.service";
import { Userinfo } from "../../data/userinfo";
import { Router , RouterStateSnapshot} from '@angular/router';
import { Location } from "@angular/common";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user:User[] = [];
  userinfo:Userinfo | undefined;

  constructor(private MemberService: MemberService,
              private auth:AuthService,
              private router: Router,
              private _location: Location,
              )
  {}

  ngOnInit(): void {

  }

  private islogin(): void{

    this.auth.isLogin().subscribe(res=>{
      if (res.err == 0){
        this.getBase();
        this.router.navigateByUrl("/index")
      }else{
        localStorage.removeItem('_token');
      }
    });
  }

  private getBase():void{
    this.MemberService.getBase({'token': localStorage.getItem('_token')})
      //.subscribe(hero => this.userinfo = hero.data);
      .subscribe(res=>
      {
        if (res.err == 0){
          this.userinfo = res.body.userinfo ;
        }
      })
  }


  login(username: string,password:string): void {
    username = username.trim();
    if (!username) { return; }
    password = password.trim();
    if (!password) { return; }
    this.MemberService.getLogin({'username': username, 'password': password} as User)
      .subscribe(res => {
        if (res.code == '200'){
          localStorage.setItem('_token',res.body.token);
          this._location.back();
        }else{
          alert(res.message)
        }
      });
  }




}
