import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MemberService } from "./service/member.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public userinfo:any
  public token = localStorage.getItem('_token');
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
              public Member:MemberService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
    this.userinfo = localStorage.getItem('userinfo');
    console.log('userinfo1',this.userinfo);
    if (this.userinfo == null){
      this.getBase();
    }

  }


  private getBase():void{
    this.Member.getBase({'_token': this.token})
      .subscribe(res=>
      {
        console.log('userData',res)
        console.log('token',this.token)
        if (res.code == 200){
          localStorage.setItem('userinfo',JSON.stringify(res.body.user_info));
        }
      })
  }

}
