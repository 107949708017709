<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h3>Login</h3>
        <div class="theme-card">
          <form class="theme-form">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" #username class="form-control" id="email" placeholder="Email" required="">
            </div>
            <div class="form-group">
              <label for="review">Password</label>
              <input #password type="password" class="form-control" id="review" placeholder="Enter your password" required="">
            </div>
            <button class="btn btn-solid" (click)="login(username.value,password.value)">Login</button>
          </form>
        </div>
      </div>
      <div class="col-lg-6 right-login">
        <h3>New Customer</h3>
        <div class="theme-card authentication-right">
          <h6 class="title-font">Create A Account</h6>
          <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
          <button class="btn btn-solid">Create an Account</button>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
