<ul class="product-social">
    <li>
      <a href="https://www.facebook.com/customartgallery ">
        <i class="fa fa-facebook"></i>
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/@custom-art-gallery">
        <i class="fa fa-google-plus"></i>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/customartcenter ">
        <i class="fa fa-twitter"></i>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/customart.gallery/">
        <i class="fa fa-instagram"></i>
      </a>
    </li>
</ul>
