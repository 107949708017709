import { Injectable } from '@angular/core';
import {Observable, map, merge,mergeWith,distinct,distinctUntilKeyChanged} from "rxjs";
import {HttpClient} from "@angular/common/http";

import {ErrorService} from "./error.service";
import {catchError, tap} from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private http: HttpClient,
    private error:ErrorService,
  ) {

  }

  getFrames(): Observable<any> {
    const res  = this.http.get('https://api2.artvillageonline.com/custom/Frames');
    return res.pipe(
      catchError(this.error.handleError<any>(''))

    );
  }
  getSize():Observable<any>{
    const res  = this.http.get('https://api2.artvillageonline.com/custom/Size');
    return res.pipe(
      catchError(this.error.handleError<any>(''))

    );
  }
}




