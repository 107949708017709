import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService} from "./error.service";
import {SettingService} from "./setting.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  _token:any;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  constructor(
    private http: HttpClient,
    private error:ErrorService,
    private setting:SettingService

  ) { }

  isLogin():Observable<any>{

    this._token = {'_token':localStorage.getItem('_token')?localStorage.getItem('_token'):''};
    const data  = this.http.post(this.setting.api.api_url+'/in/Rtoken',this._token,this.httpOptions);
    return data.pipe(
      tap(_ => this.error.log('fetched token')),
      catchError(this.error.handleError<any>('fetched token'))
    );
  }

}
