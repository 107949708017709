import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from '../../shared/data/slider';
import { Product } from '../../shared/classes/product';
import { ProductService } from '../../shared/services/product.service';
import { SizeModalComponent } from "../../shared/components/modal/size-modal/size-modal.component";
import { ProductsService} from "../../service/products.service";
import {OwlOptions} from "ngx-owl-carousel-o";
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  public customOptions: OwlOptions = {

    loop: true,
    margin:3,
    nav: true,
    dots: false,
    autoWidth:true,
    navSpeed: 120,
    slideBy:5,
    navText: ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'],


  };
  public product: Product = {};
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  public active = 1;
  public finishing_id = 3;
  public frames :any;
  public girth:number;
  public length :number;
  public height:number;

  @ViewChild("sizeChart") SizeChart: SizeModalComponent;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

  constructor(private route: ActivatedRoute, private router: Router,
              public products: ProductsService,
              public productService: ProductService) {
    this.route.data.subscribe(response => this.product = response.data);
    this.getFrames();
    this.girth = (this.product.length*1 + this.product.height*1) * 0.0254;
    this.length = this.product.length;
    this.height = this.product.height;
  }

  ngOnInit(): void {

  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size)
      }

    }
    ;
    return uniqSize
  }

  public setSize(size) {
    alert('aaa')
    this.selectedSize = size;
   // document.getElementsByClassName('sizebox').value;
      console.log(this.selectedSize)
    this.girth = (this.selectedSize.length*1 + this.selectedSize.height*1) * 0.0254;

    this.length = this.selectedSize.length*1
    this.height = this.selectedSize.height*1
  }

  // Increament
  increment() {
    this.counter++;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter--;
  }

  // Add to cart
  async addToCart(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if (status)
      this.router.navigate(['/shop/cart']);
  }

  // Buy Now
  async buyNow(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if (status)
      this.router.navigate(['/shop/checkout']);
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  getFrames(){
    this.products.getFrames().subscribe(res=>{
      console.log('frames',res);
      if (res.code=='1'){
        this.frames = res.data;
        console.log('frames',this.frames);
      }
    })
  }
  public frame_src = '';
  public img_frame = 0;

  setFrame(id:number,type:number){
    {
      console.log('images',this.product.images);
      this.frame_src = '';
      let img
      if (this.img_frame == 0){
        img = this.product.images[0]
      }else{
        img = this.product.images[1]
      }
      if (type == 1){
        this.frame_src = 'https://custom.artvo.com/frame/bac/'+id+'.html?file='+img+'&unit=inches&w='+this.length+'&h='+this.length;
      }else{
        this.frame_src = 'https://custom.artvo.com/frame/Cus/'+id+'.html?file='+img+'&unit=inches&w='+this.length+'&h='+this.length;
      }

      /*if (type == 1){
        this.frame_src = 'https://custom.artvo.com/frame/bac/'+id+'.html?file='+this.product.images[0]
      }else{
        this.frame_src = 'https://custom.artvo.com/frame/Cus/'+id+'.html?file='+this.product.images[0]
      }*/
      if (this.img_frame == 0){
        this.product.images.unshift(this.frame_src)
        this.img_frame = 1;
      }else{
        this.product.images[0] = this.frame_src
      }


    }
  }

}
