import { Injectable } from '@angular/core';
declare var $:any
@Injectable({
  providedIn: 'root'
})
export class SettingService {

  public api:any;
  constructor() {
    this.api = {
      api_url: "https://api.artvillageonline.com",
      http_url: "https://im.artvillageonline.com",
      static_url: "https://im.artvillageonline.com/static/",
      socket_url: "wss://ws.artvillageonline.com"
    }
  }
}
