import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  Category:any;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
            public productService: ProductService,
            private http: HttpClient,
              ) {

    this.productService.getProducts.subscribe(product => this.products = product);
    this.filterbyCategory();


  }

  ngOnInit(): void {
  }

filterbyCategory() {
    let category;
    this.http.get<any>('https://api.artvillageonline.com/category/index',this.httpOptions).subscribe(data => {
      //  uniqueBrands = data.body
       this.Category = data.body
      //console.log('brand',data.body);

    });

   /* //const category = [...new Set(this.products.map(product => product.type))]
    return category*/
  }

}
